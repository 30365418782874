
import { defineAsyncComponent, nextTick, watch } from "@vue/runtime-core";
import HtmlClass from "@/core/services/LayoutService";
import { Actions } from "@/store/enums/StoreEnums";
import { onMounted } from "vue";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { useStore } from 'vuex';
import KTLoader from "@/components/Loader.vue";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import { useRoute } from 'vue-router';
import { MenuComponent } from '@/assets/ts/components';
import { removeModalBackdrop } from '@/core/helpers/dom';
export default {
  components: {
    Navbar: defineAsyncComponent(
      () => import("./Navbar.vue")
    ),
    Footer: defineAsyncComponent(
      () => import("./Footer.vue")
    ),
    KTLoader
  },
  setup() {
    // show page loading
    const store = useStore();
    const route = useRoute();
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    // initialize html element classes
    HtmlClass.init();
    onMounted(() => {
      //check if current user is authenticated

      nextTick(() => {
        reinitializeComponents();
      });
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });
    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        removeModalBackdrop();
        nextTick(() => {
          reinitializeComponents();
        });
      }
    );
  
    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      themeLightLogo,
      themeDarkLogo,
    };
  },
};
